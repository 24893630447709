// react
import React from "react";
import {applySnapshot, flow, Instance, types} from "mobx-state-tree"
import axios from "axios";
import packageJson from '../../package.json';
import {Dropdown} from "react-bootstrap";
import moment from "moment/moment";

// local
import {
	LOCAL_STORAGE,
	LOGIN_SETTINGS,
	ROLE_ACCOUNT_MANAGER,
	ROLE_ADMIN,
	ROLE_COMPANY_MANAGER,
	ROLE_DEPARTMENT_MANAGER,
	ROLE_USER,
	UTC_DATE_FORMAT
} from "./variables";
import {timestampToStrDate} from "./utils";

export const webservice = axios.create({
	baseURL: (packageJson.globals.__DEV__) ? packageJson.globals.__DEV_API__ : packageJson.globals.API_URL,
	url: '/webservice/rest/server.php',
	method: 'POST',
	withCredentials: false,
	//headers: {'Cookie': 'XDEBUG_SESSION=11475'}
});

/**
 * General Resources
 */

export interface NameValuePair {
	name: string,
	value: string | number,
}

const KeyValueInfo = types.model("KeyValuePair", {
	id: types.string,
	name: types.string,
	value: types.union(types.number, types.string),
	color: types.maybeNull(types.string)
});
export type KeyValuePair = Instance<typeof KeyValueInfo>;

export const PageNavInfo = types.model("PageNavInfo", {
	// general
	coupons: types.string,
	courses: types.string,
	engagement: types.string,
	insights: types.string,
	location: types.string,
	sales: types.string,
	settings: types.string,
	students: types.string,
	survey: types.string,
	// student
	s_return: types.string,
	s_orders: types.string,
	s_courses: types.string,
	s_exams: types.string,
	s_certificates: types.string,
	s_validation: types.string,
	s_logins: types.string,
	// other
	support: types.string
}).actions(self => {

	function init(values: NameValuePair[]) {
		values.forEach(item => {
			// @ts-ignore
			self[item.name] = item.value;
		});
	}

	function toggle(section: string) {
		// @ts-ignore
		self[section] = (self[section] === 'show') ? 'selected' : 'show';
		applySnapshot(self, {...self});
	}

	return {
		init,
		toggle
	}
}).views(self => ({

	selected() {
		return {
			coupons: self.coupons === 'selected',
			courses: self.courses === 'selected',
			engagement: self.engagement === 'selected',
			insights: self.insights === 'selected',
			location: self.location === 'selected',
			sales: self.sales === 'selected',
			settings: self.settings === 'selected',
			students: self.students === 'selected',
			survey: self.survey === 'selected',
			// student
			s_return: self.s_return === 'selected',
			s_orders: self.s_orders === 'selected',
			s_courses: self.s_courses === 'selected',
			s_exams: self.s_exams === 'selected',
			s_certificates: self.s_certificates === 'selected',
			s_validation: self.s_validation === 'selected',
			s_logins: self.s_logins === 'selected',
			// other
			support: !!self.support
		}
	},

	show() {
		return {
			coupons: !!self.coupons,
			courses: !!self.courses,
			engagement: !!self.engagement,
			insights: !!self.insights,
			location: !!self.location,
			sales: !!self.sales,
			settings: !!self.settings,
			students: !!self.students,
			survey: !!self.survey,
			// student
			s_return: !!self.s_return,
			s_orders: !!self.s_orders,
			s_courses: !!self.s_courses,
			s_exams: !!self.s_exams,
			s_certificates: !!self.s_certificates,
			s_validation: !!self.s_validation,
			s_logins: !!self.s_logins,
			// other
			support: !!self.support
		}
	}

}));
export type PageNav = Instance<typeof PageNavInfo>;

export interface ApiFieldOption {
	id: string,
	title: string,
	type: string,
	values: string,
	default: string
}
export interface ApiIssueRecord {
	id: number,
	created: string,
	description: string,
	environment: string,
	priority: string,
	status: string,
	reporter: string
}
export interface ApiFeatureRecord {
	id: number,
	created: string,
	description: string,
	environment: string,
	priority: string,
	status: string,
	reporter: string
}

/**
 * Moodle resources
 */

export const ActivitySummaryInfo = types.model("ActivitySummaryInfo", {
	year: types.number,
	month: types.number,
	activity_id: types.number,
	activity_category: types.string,
	activity_type: types.string,
	activity_title: types.string,
	total: types.number,
	started: types.number,
	started_percent: types.number,
	completed: types.number,
	completed_percent: types.number,
	color: types.maybeNull(types.string)
})
export type ActivitySummary = Instance<typeof ActivitySummaryInfo>;

export const CareSummaryInfo = types.model("CareSummaryInfo", {
	activity_name: types.string,
	all_total: types.number,
	all_percent: types.number,
	company_total: types.number,
	company_percent: types.number
})
export type CareSummary = Instance<typeof CareSummaryInfo>;

export const LocationSummaryInfo = types.model("LocationSummaryInfo", {
	id: types.number,
	name: types.string,
	users: types.number,
	access: types.number,
	engagements: types.array(types.number),
	turnover: types.array(types.number),
	courses_total: types.number,
	courses_started: types.number,
	courses_completed: types.number,
})
export type LocationSummary = Instance<typeof LocationSummaryInfo>;

export const MdlResource = types.model("MdlResource", {
	activity_id: types.number,
	activity_type: types.string,
	activity_category: types.string,
	activity_name: types.string,
	activity_title: types.string,
	engagements: types.number,
	ave_time: types.number
});
export type Resource = Instance<typeof MdlResource>;

export const MdlResourceGroup = types.model("MdlResourceGroup", {
	care: types.array(MdlResource),
	hope: types.array(MdlResource),
	training: types.array(MdlResource),
	page: types.array(MdlResource),
	course: types.array(MdlResource),
	post: types.array(MdlResource),
	story: types.array(MdlResource),
	app: types.array(MdlResource),
	site: types.array(MdlResource),
});
export type ResourceGroup = Instance<typeof MdlResourceGroup>;

export const PollResponseInfo = types.model("PollResponseInfo", {
	id: types.number,
	year: types.number,
	month: types.number,
	company: types.string,
	question: types.string,
	response: types.string,
	total: types.number
})
export type PollResponse = Instance<typeof PollResponseInfo>;

export const PollSummaryInfo = types.model("PollSummaryInfo", {
	id: types.string,
	year: types.number,
	month: types.number,
	company: types.string,
	title: types.string,
	started: types.number,
	started_percent: types.number,
	completed: types.number,
	completed_percent: types.number,
	total: types.number
})
export type PollSummary = Instance<typeof PollSummaryInfo>;

export const CourseInfoModel = types.model("CourseInfoModel", {
	id: types.number,
	company_id: types.number,
	company_name: types.string,
	user_id: types.number,
	user_last_name: types.string,
	user_first_name: types.string,
	email: types.string,
	phone: types.string,
	course_id: types.number,
	course_title: types.string,
	enrolled: types.number,
	started: types.number,
	completed: types.number,
	mod_completed: types.number,
	mod_total: types.number,
	total: types.number,
	// presentation & search
	search: types.string,
	sEnrolled: types.string,
	sStarted: types.string,
	sCompleted: types.string,
	sScore: types.string,
	sPercent: types.string
})
export type CourseInfo = Instance<typeof CourseInfoModel>;

export const SalesInfoModel = types.model("SalesInfoModel", {
	id: types.number,
	company_id: types.number,
	company_name: types.string,
	user_id: types.number,
	user_last_name: types.string,
	user_first_name: types.string,
	email: types.string,
	phone: types.string,
	postcode: types.string,
	created: types.number,
	order_id: types.number,
	order_item_id: types.number,
	order_item_name: types.string,
	qty: types.number,
	subtotal: types.number,
	coupon: types.string,
	discount: types.number,
	total: types.number,
	// moodle
	member_id: types.string,
	mdl_user_id: types.number,
	// presentation & search
	search: types.string,
	sCreated: types.string
})
export type SalesInfo = Instance<typeof SalesInfoModel>;

export const CouponUseInfoModel = types.model("CouponUseInfoModel", {
	id: types.number,
	created: types.number,
	coupon_id: types.string,
	coupon_name: types.string,
	coupon_amount: types.number,
	user_id: types.number,
	user_email: types.string,
	user_last_name: types.string,
	user_first_name: types.string
})
export type CouponUseInfo = Instance<typeof CouponUseInfoModel>;

export const CouponInfoModel = types.model("CouponInfoModel", {
	id: types.number,
	created: types.number,
	coupon_id: types.string,
	coupon_category: types.string,
	coupon_name: types.string,
	coupon_type: types.string,
	coupon_amount: types.number,
	coupon_use: types.number,
	coupon_limit: types.number,
	use: types.array(CouponUseInfoModel),
	// search
	search: types.string
})
export type CouponInfo = Instance<typeof CouponInfoModel>;

export interface MdlItemCount {
	company_id: number,
	company_name: string,
	engagements: number,
	modified: boolean
}
export interface MdlResourceCount {
	census: MdlItemCount[],
	care: MdlItemCount[],
	hope: MdlItemCount[],
	app: MdlItemCount[],
	site: MdlItemCount[],
	training: MdlItemCount[],
	page: MdlItemCount[],
	course: MdlItemCount[],
	post: MdlItemCount[],
	story: MdlItemCount[],
}

/**
 * SystemInfo - This structure is used for mobx storage efficiency.
 */
export const SystemInfo = types.model("System", {
	// basic
	initialized: types.boolean,
	loading: types.boolean,
	superAdmin: types.boolean,
	// advanced
	appName: types.string,
	appLogo: types.string,
	appCopyright: types.string,
	apiUrl: types.string,
	apiKey: types.string,
	rootId: types.string,
	rootUrl: types.string,
	rootLogo: types.string,
	passwordUrl: types.string,
	// runtime
	userid: types.string,
	token: types.string
}).actions(self => {

	function clear() {
		applySnapshot(self, {...self, userid: "", token: ""});
	}

	function clearStorage(keyName: string) {
		localStorage.removeItem(keyName);
		sessionStorage.removeItem(keyName);
		if (keyName === LOGIN_SETTINGS) {
			applySnapshot(self, {...self, userid: "", token: ""});
		}
	}

	const generatePDF = flow(function* generatePDF(html: string) {
		const pdfservice = axios.create({
			baseURL: 'http://rpaulsen.iomad.com',
			url: '/local/lecticon/testpdf.php',
			method: 'POST',
			withCredentials: false,
		});

		const options = new FormData();
		options.append('html', html);

		const results = yield pdfservice.post('/local/lecticon/testpdf.php', options);
		return results;
	})

	function getStorage(keyName: string) {
		let dstData = localStorage.getItem(keyName);
		if (!dstData) {
			dstData = sessionStorage.getItem(keyName);
		}
		return dstData ? JSON.parse(dstData) : null;
	}

	function initialize() {
		// setup
		if (self.initialized) {
			return;
		}
		let userid = "";
		let token = "";
		let rootUrl = self.rootUrl;

		// Get the values.
		const query = window.location.search.substr(1).split('&');
		const superAdmin = (window.location.hostname == 'localhost');

		// Check the stored user authentication, if any.
		const login = getStorage(LOGIN_SETTINGS);
		if (login && login.user_id && login.user_auth) {
			userid = login.user_id;
			token = login.user_auth;
		}

		// Parse the query params, if any.
		for (let i = 0; i < query.length; ++i) {
			let parts = query[i].split('=', 2);
			if (parts[0] === 'userid') {
				userid = parts[1].toString();
			} else if (parts[0] === 'token') {
				token = parts[1];
			} else if (parts[0] === 'url') {
				rootUrl = parts[1];
			}
		}

		// Apply the results.
		applySnapshot(self, {...self, loading: false, userid, token, rootUrl, superAdmin}); // for protection
	}

	function setStorage(keyName: string, srcData: any, storage: string) {
		if (storage === LOCAL_STORAGE && srcData) {
			localStorage.setItem(keyName, JSON.stringify(srcData));
		} else if (storage === LOCAL_STORAGE && srcData) {
		} else if (srcData) {
			sessionStorage.setItem(keyName, JSON.stringify(srcData));
		}
	}

	return {
		clear,
		clearStorage,
		initialize,
		generatePDF,
		getStorage,
		setStorage
	}
})

export type System = Instance<typeof SystemInfo>;

export const system = SystemInfo.create({
	// basic
	initialized: false,
	loading: false,
	superAdmin: false,
	// advanced
	appName: packageJson.globals.APP_NAME,
	appLogo: packageJson.globals.APP_LOGO,
	appCopyright: packageJson.globals.APP_COPYRIGHT,
	apiUrl: (packageJson.globals.__DEV__) ? packageJson.globals.__DEV_API__ : packageJson.globals.API_URL,
	apiKey: packageJson.globals.API_KEY,
	rootId: packageJson.globals.ROOT_ID,
	rootUrl: packageJson.globals.ROOT_URL,
	rootLogo: packageJson.globals.ROOT_LOGO,
	passwordUrl: packageJson.globals.PASSWORD_URL,
	// runtime
	userid: '',
	token: ''
});

/**
 * ClientInfo - This structure is used for mobx storage efficiency.
 */

export const DepartmentInfo = types.model("DepartmentInfo", {
	id: types.string,
	name: types.string
})
export type Department = Instance<typeof DepartmentInfo>;

const CompanyInfo = types.model("CompanyInfo", {
	id: types.number,
	parentid: types.number,
	level: types.number,
	name: types.string,
	shortname: types.string,
	code: types.string,
	logo: types.string,
	census: types.number,
	departments: types.array(DepartmentInfo)
});
export type Company = Instance<typeof CompanyInfo>;

export interface CompanyDetails {
	// basic
	id: number,
	name: string,
	shortname: string,
	parentid: number,
	code: string,
	logo: string,
	// address
	address: string,
	city: string,
	postcode: string,
	country: string,
	locale: string,
	// contact
	contact_name: string,
	contact_email: string,
	contact_phone: string,
	// billing
	broker_name: string,
	broker_email: string,
	broker_phone: string,
	// billing
	billing_name: string,
	billing_email: string,
	billing_phone: string,
	// other
	census: number,
	office_census: number,
	other_census: number,
	status: string,
	industry: string,
	validfrom: number,
	validto: number,
	contract_level: string,
	pricing: string,
	primary_insurance: string,
	service: string,
	purpose: string,
	values: string,
	departments: Department[]
	// strings
	btnColor: string,
	talkNumber: string,
	textNumber: string,
}

export const ClientInfo = types.model("ClientInfo", {
	// data
	id: types.number,
	name: types.string,
	logo: types.string,
	url: types.string,
	departments: types.array(DepartmentInfo),
	departmentId: types.string,
	schoolId: types.number,
	pageNavigation: PageNavInfo,
	// stats
	census: types.number,
	// status
	initialized: types.boolean,
	loading: types.boolean
}).volatile(_ => ({
	// summary
	companyStats: [] as ActivitySummary[],
	locationStats: [] as LocationSummary[],
	annualStats: [] as ActivitySummary[],
	monthlyStats: [] as ActivitySummary[],
	careReason: [] as CareSummary[],
	careWho: [] as CareSummary[],
	courseType: 'Engagement' as string,
	courseSummary: [] as ActivitySummary[],
	courseBreakdown: [] as ActivitySummary[],
	// sales
	sales: [] as SalesInfo[],
	salesCompanyId: '' as string,
	salesStartDate: '' as string,
	salesEndDate: '' as string,
	// courses
	courses: [] as CourseInfo[],
	coursesCompanyId: '' as string,
	coursesStartDate: '' as string,
	coursesEndDate: '' as string,
	//
	pollCompanyId: '' as string,
	pollSummary: [] as PollSummary[],
	pollBreakdown: [] as PollResponse[],
	pollTimeframe: 'month' as string,
	// coupons
	couponsSummary: [] as SalesInfo[],
	couponsBreakdown: [] as CouponInfo[],
	couponsCompanyId: '' as string,
	couponsStartDate: '' as string,
	couponsEndDate: '' as string,
	// support
	issuesBoard: '' as string,
	issuesUrl: '' as string,
	issuesWorkspace: '' as string,
	issuesOptions: [] as ApiFieldOption[],
	featuresBoard: '' as string,
	featuresUrl: '' as string,
	featuresWorkspace: '' as string,
	featuresOptions: [] as ApiFieldOption[],
	// start-end date
	startDate: '' as string,
	endDate: '' as string
})).actions(self => {

	function clearStats() {
		applySnapshot(self, defaultClient);
	}

	const fetchVestAnalytics = async (companyid: string, departmentid: string, startDate: string, endDate: string) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'bn_get_company_analytics');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('departmentid', departmentid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			// setup
			const census = self.census ? self.census : 10;

			// fixups
			const date = moment(endDate);
			const year = date.year();
			const month = date.month();
			let company_summary = results.data.company_summary;
			for (let i = 0; i < company_summary.length; i++) {
				const activity_type = 'utilization';
				const item = company_summary[i];
				if (item.activity_type === 'care') {
					// Compute the care-center usage (based on total interactions / census).
					const total = item.total * 100 / census;
					company_summary.push({
						...item, activity_type, total,
						started: total, started_percent: 100, completed: total, completed_percent: 100
					});
				}
			}
			company_summary.push({
				year, month, activity_id: 0, activity_category: "", activity_type: 'census', activity_title: 'Census',
				total: census, started: census, started_percent: 100, completed: census, completed_percent: 100
			});

			// fixups
			let company_breakdown = results.data.company_breakdown;
			for (let i = 0; i < company_breakdown.length; i++) {
				const activity_type = 'utilization';
				const item = company_breakdown[i];
				if (item.activity_type === 'care') {
					const total = item.total * 100 / census;
					company_breakdown.push({
						...item, activity_type, total,
						started: total, started_percent: 100, completed: total, completed_percent: 100
					});
				}
			}

			return {
				company_summary,
				company_breakdown,
				care_reason: results.data.care_reason,
				care_who: results.data.care_who,
				course_summary: results.data.course_summary,
				course_breakdown: results.data.course_breakdown
			};
		}
		return {
			company_summary: [],
			company_breakdown: [],
			care_reason: [],
			care_who: [],
			course_summary: [],
			course_breakdown: []
		};
	}

	const fetchCouponAnalytics = flow(function*
		fetchCouponAnalytics(companyid: string, startDate: string, endDate: string, force: boolean) {
		// Check for existing courses.
		if (!force && self.couponsSummary.length && self.couponsCompanyId === companyid &&
			self.couponsStartDate === startDate && self.couponsEndDate === endDate) {
			return {
				summary: self.couponsSummary,
				breakdown: self.couponsBreakdown
			};
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'wp_get_coupons');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			self.couponsSummary = results.data.summary;
			self.couponsBreakdown = results.data.breakdown;
			for (let i = 0; i < self.couponsBreakdown.length; i++) {
				let item = self.couponsBreakdown[i];
				let search = item.coupon_id + '-' + item.coupon_name;
				for (let j = 0; j < item.use.length; j++) {
					const use = item.use[j];
					search += + '-' + use.coupon_id + '-' + use.coupon_name + '-' +
						use.user_last_name + '-' + use.user_first_name;
				}
				item.search = search.toLowerCase();
			}
		} else {
			self.couponsSummary = [];
			self.couponsBreakdown = [];
		}
		self.couponsCompanyId = companyid;
		self.couponsStartDate = startDate;
		self.couponsEndDate = endDate;
		return {
			summary: self.couponsSummary,
			breakdown: self.couponsBreakdown
		};
	})

	const fetchCoursesAnalytics = flow(function*
		fetchCoursesAnalytics(companyid: string, startDate: string, endDate: string, force: boolean) {
		// Check for existing courses.
		if (!force && self.courses.length &&
			self.coursesCompanyId === companyid &&
			self.coursesStartDate === startDate && self.coursesEndDate === endDate) {
			return self.courses;
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_courses_details');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		self.courses = (results && results.status === 200) ? results.data : [];
		self.courses.forEach(item => {
			// Fixups for presentation and search.
			item.sEnrolled = timestampToStrDate(item.enrolled, false);
			item.sStarted = timestampToStrDate(item.started, false);
			item.sCompleted = timestampToStrDate(item.completed, false);
			item.sScore = (item.completed && item.total) ? item.total + '%' : '';
			item.sPercent = item.mod_completed ? Math.ceil(item.mod_completed * 100 / item.mod_total) + '%' : '';
			item.search = (item.id + '-' +
				item.user_first_name + '-' + item.user_last_name + '-' +
				item.email + '-' + item.phone + '-' +
				item.company_name + '-' +
				item.course_title + '-' +
				item.enrolled + '-' + item.started + '-' + item.completed + '-' +
				item.sScore + '-' + item.sPercent).toLowerCase();
		});
		self.coursesCompanyId = companyid;
		self.coursesStartDate = startDate;
		self.coursesEndDate = endDate;
		return self.courses;
	})

	const fetchMdlAnalytics = async (companyid: string, startDate: string, endDate: string) => {
		// setup
		let wsfunction = 'mdl_get_company_analytics'; // TODO - TEMPORARY
		if (system.rootUrl.includes('haven')) {
			wsfunction = 'hh_get_company_analytics';
		} else if (system.rootUrl.includes('aaadrivingcourse')) {
			wsfunction = 'aaa_get_company_analytics';
		} else if (system.rootUrl.includes('washingtonagd')) {
			wsfunction = 'aaa_get_company_analytics';
		}
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', wsfunction);
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			const data = results.data;
			return {
				company_summary: data.company_summary ? data.company_summary : [],
				course_summary: data.course_summary ? data.course_summary : [],
				course_breakdown: data.course_breakdown ? data.course_breakdown : []
			};
		}
		return {
			company_summary: [],
			course_summary: [],
			course_breakdown: []
		};
	}

	const fetchSalesAnalytics = flow(function*
		fetchSalesAnalytics(companyid: string, startDate: string, endDate: string, force: boolean) {
		// Check for existing courses.
		if (!force && self.sales.length && self.salesCompanyId === companyid &&
			self.salesStartDate === startDate && self.salesEndDate === endDate) {
			return self.sales;
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'wp_get_orders');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			self.sales = results.data;
			self.sales.forEach(item => {
				// Fixups for presentation and search.
				item.sCreated = timestampToStrDate(item.created, false);
				item.search = (item.id + '-' +
					item.sCreated + '-' +
					item.user_first_name + '-' + item.user_last_name + '-' +
					item.email + '-' + item.phone + '-' +
					item.company_name  + '-' + item.member_id + '-' +
					item.coupon).toLowerCase();
			});
		} else {
			self.sales = [];
		}
		self.salesCompanyId = companyid;
		self.salesStartDate = startDate;
		self.salesEndDate = endDate;
		return self.sales;
	})

	const fetchStudentDetails = async (userid: number, orderid: number, courseid: number) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_student_details');
		options.append('moodlewsrestformat', 'json');
		options.append('userid', userid.toString());
		options.append('orderid', orderid.toString());
		options.append('courseid', courseid.toString());

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			return results.data;
		}
		return null;
	}

	const fetchSurveyAnalytics = flow(function* fetchSurveyAnalytics(companyid: string, timeframe: string, force: boolean) {
		// setup
		if (!force && companyid === self.pollCompanyId && timeframe === self.pollTimeframe && self.pollSummary.length) {
			return {summary: self.pollSummary, breakdown: self.pollBreakdown, timeframe: self.pollTimeframe};
		}

		let wsfunction = 'bn_get_survey';
		if (system.rootUrl.includes('haven')) {
			wsfunction = 'hh_get_survey';
		}
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', wsfunction);
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('timeframe', timeframe);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		self.pollCompanyId = companyid;
		if (results && results.status === 200) {
			self.pollSummary = results.data.summary;
			self.pollBreakdown = results.data.responses;
		} else {
			self.pollSummary = [];
			self.pollBreakdown = [];
		}
		self.pollTimeframe = timeframe;
		return {summary: self.pollSummary, breakdown: self.pollBreakdown, timeframe: self.pollTimeframe};
	})

	const generateReport = async (companyName: string, report: string, startDate: string, endDate: string) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_generate_report');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyName);
		options.append('report', report);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		const type = 'text/csv';
		const blob = new Blob([results.data], { type: type });
		const dataURI = `data: ${type};charset=utf-8,` + results.data;

		const URL = window.URL || window.webkitURL;
		const filename = `${report}-${companyName}-${startDate}-${endDate}.csv`;
		const href = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

		let link = document.createElement('a');
		link.download = filename;
		link.href = href;
		link.target = '_blank';
		link.click();
	}

	function initialize() {
		// Get the list of clients.
		if (!self.initialized) {
			reset();
			applySnapshot(self, {...self, initialized: true});
		}
	}

	const initAAA = flow(function*
		initAAA(client: Company, startDate: string, endDate: string, departments: any[], departmentId: string) {
		// navigation
		self.pageNavigation.init([
			{name: 'sales', value: 'selected'},
			{name: 'students', value: 'selected'},
			{name: 'coupons', value: 'selected'},
			{name: 'support', value: 'selected'}
		]);

		// support
		self.issuesUrl = 'https://forms.monday.com/forms/embed/80c3b0ea1ff179936b54c62f499b176a?r=use1';
		self.issuesWorkspace = '4383391';
		self.issuesBoard = '5829692304';
		self.issuesOptions = [
			{ id: 'description', title: 'Description', type: 'textarea', values: '', default: ''},
			{ id: 'environment', title: 'Environment', type: 'select-single', values: '[{"id":"web","label":"Web Reporting"},{"id":"eap-iphone","label":"VESTeap (iPhone)"},{"id":"eap-android","label":"VESTeap (Android)"},{"id":"fr-iphone","label":"VEST First Responder (iPhone)"},{"id":"fr-android","label":"VEST First Responder (Android)"}]', default: 'web'},
			{ id: 'priority', title: 'Priority', type: 'select-single', values: '[{"id":"high", "label":"High"}, {"id":"medium", "label":"Medium"}, {"id":"low", "label":"Low"}]', default: 'medium'},
			{ id: 'requestor', title: 'Requestor', type: 'text', values: '', default: ''},
		];
		self.featuresUrl = 'https://forms.monday.com/forms/embed/ca3fb2600a5b5adbedc3203d2efa4eee?r=use1';
		self.featuresWorkspace = '4383391';
		self.featuresBoard = '5829900292';
		self.featuresOptions = [
			{ id: 'description', title: 'Description', type: 'textarea', values: '', default: ''},
			{ id: 'environment', title: 'Environment', type: 'select-single', values: '[{"id":"web","label":"Web Reporting"},{"id":"eap-iphone","label":"VESTeap (iPhone)"},{"id":"eap-android","label":"VESTeap (Android)"},{"id":"fr-iphone","label":"VEST First Responder (iPhone)"},{"id":"fr-android","label":"VEST First Responder (Android)"}]', default: 'web'},
			{ id: 'priority', title: 'Priority', type: 'select-single', values: '[{"id":"high", "label":"High"}, {"id":"medium", "label":"Medium"}, {"id":"low", "label":"Low"}]', default: 'medium'},
			{ id: 'requestor', title: 'Requestor', type: 'text', values: '', default: ''},
		];

		// stats
		const stats = yield fetchMdlAnalytics(client.code, startDate, endDate);
		self.locationStats = stats.company_summary;
		self.courseType = 'Activity';
		self.courseSummary = stats.course_summary;
		self.courseBreakdown = stats.course_breakdown;
		applySnapshot(self, {...self, id: client.id, name: client.name, logo: client.logo,
			departments, departmentId, schoolId: 0,
			census: client.census,
			loading: false});
	})

	const initHaven = flow(function*
		initHaven(client: Company, startDate: string, endDate: string, departments: any[], departmentId: string) {
		// navigation
		self.pageNavigation.init([
			{name: 'location', value: 'selected'},
			{name: 'courses', value: 'selected'}
		]);

		// stats
		const stats = yield fetchMdlAnalytics(client.code, startDate, endDate);
		self.locationStats = stats.company_summary;
		self.courseType = 'Activity';
		self.courseSummary = stats.course_summary;
		self.courseBreakdown = stats.course_breakdown;
		applySnapshot(self, {
			...self, id: client.id, name: client.name, logo: client.logo,
			departments, departmentId, schoolId: 0,
			census: client.census,
			loading: false
		});
	})

	const initOnQ = flow(function*
		initOnQ(client: Company, startDate: string, endDate: string, departments: any[], departmentId: string) {
		// navigation
		self.pageNavigation.init([
			{name: 'sales', value: 'selected'},
			{name: 'students', value: 'selected'},
			{name: 'coupons', value: 'selected'},
			{name: 'support', value: 'selected'}
		]);

		// support
		self.issuesUrl = 'https://forms.monday.com/forms/embed/80c3b0ea1ff179936b54c62f499b176a?r=use1';
		self.issuesWorkspace = '4383391';
		self.issuesBoard = '5829692304';
		self.issuesOptions = [
			{ id: 'description', title: 'Description', type: 'textarea', values: '', default: ''},
			{ id: 'environment', title: 'Environment', type: 'select-single', values: '[{"id":"web","label":"Web Reporting"},{"id":"eap-iphone","label":"VESTeap (iPhone)"},{"id":"eap-android","label":"VESTeap (Android)"},{"id":"fr-iphone","label":"VEST First Responder (iPhone)"},{"id":"fr-android","label":"VEST First Responder (Android)"}]', default: 'web'},
			{ id: 'priority', title: 'Priority', type: 'select-single', values: '[{"id":"high", "label":"High"}, {"id":"medium", "label":"Medium"}, {"id":"low", "label":"Low"}]', default: 'medium'},
			{ id: 'requestor', title: 'Requestor', type: 'text', values: '', default: ''},
		];
		self.featuresUrl = 'https://forms.monday.com/forms/embed/ca3fb2600a5b5adbedc3203d2efa4eee?r=use1';
		self.featuresWorkspace = '4383391';
		self.featuresBoard = '5829900292';
		self.featuresOptions = [
			{ id: 'description', title: 'Description', type: 'textarea', values: '', default: ''},
			{ id: 'environment', title: 'Environment', type: 'select-single', values: '[{"id":"web","label":"Web Reporting"},{"id":"eap-iphone","label":"VESTeap (iPhone)"},{"id":"eap-android","label":"VESTeap (Android)"},{"id":"fr-iphone","label":"VEST First Responder (iPhone)"},{"id":"fr-android","label":"VEST First Responder (Android)"}]', default: 'web'},
			{ id: 'priority', title: 'Priority', type: 'select-single', values: '[{"id":"high", "label":"High"}, {"id":"medium", "label":"Medium"}, {"id":"low", "label":"Low"}]', default: 'medium'},
			{ id: 'requestor', title: 'Requestor', type: 'text', values: '', default: ''},
		];

		// stats
		const stats = yield fetchMdlAnalytics(client.code, startDate, endDate);
		self.locationStats = stats.company_summary;
		self.courseType = 'Activity';
		self.courseSummary = stats.course_summary;
		self.courseBreakdown = stats.course_breakdown;
		applySnapshot(self, {...self, id: client.id, name: client.name, logo: client.logo,
			departments, departmentId, schoolId: 0,
			census: client.census,
			loading: false});
	})

	const initVEST = flow(function*
		initVEST(client: Company, startDate: string, endDate: string, departments: any[], departmentId: string) {
		// navigation
		self.pageNavigation.init([
			{name: 'engagement', value: 'selected'},
			{name: 'courses', value: 'selected'},
			{name: 'insights', value: 'show'},
			{name: 'settings', value: 'selected'}
		]);

		// support
		self.issuesUrl = 'https://forms.monday.com/forms/embed/640e0689a9ed97623e7d67a89d50a8f9?r=use1';
		self.issuesWorkspace = '4523136';
		self.issuesBoard = '5897017376';
		self.issuesOptions = [
			{ id: 'description', title: 'Description', type: 'textarea', values: '', default: ''},
			{ id: 'environment', title: 'Environment', type: 'select-single', values: '[{"id":"web","label":"Web Reporting"},{"id":"eap-iphone","label":"VESTeap (iPhone)"},{"id":"eap-android","label":"VESTeap (Android)"},{"id":"fr-iphone","label":"VEST First Responder (iPhone)"},{"id":"fr-android","label":"VEST First Responder (Android)"}]', default: 'web'},
			{ id: 'priority', title: 'Priority', type: 'select-single', values: '[{"id":"high", "label":"High"}, {"id":"medium", "label":"Medium"}, {"id":"low", "label":"Low"}]', default: 'medium'},
			{ id: 'requestor', title: 'Requestor', type: 'text', values: '', default: ''},
		];
		self.featuresUrl = 'https://forms.monday.com/forms/embed/31f9156732b0df660c5b6555b56c910e?r=use1';
		self.featuresWorkspace = '4523136';
		self.featuresBoard = '5897030848';
		self.featuresOptions = [
			{ id: 'description', title: 'Description', type: 'textarea', values: '', default: ''},
			{ id: 'environment', title: 'Environment', type: 'select-single', values: '[{"id":"web","label":"Web Reporting"},{"id":"eap-iphone","label":"VESTeap (iPhone)"},{"id":"eap-android","label":"VESTeap (Android)"},{"id":"fr-iphone","label":"VEST First Responder (iPhone)"},{"id":"fr-android","label":"VEST First Responder (Android)"}]', default: 'web'},
			{ id: 'priority', title: 'Priority', type: 'select-single', values: '[{"id":"high", "label":"High"}, {"id":"medium", "label":"Medium"}, {"id":"low", "label":"Low"}]', default: 'medium'},
			{ id: 'requestor', title: 'Requestor', type: 'text', values: '', default: ''},
		];

		// stats
		const stats = yield fetchVestAnalytics(client.code, departmentId, startDate, endDate);
		self.annualStats = stats.company_summary;
		self.monthlyStats = stats.company_breakdown;
		self.careReason = stats.care_reason;
		self.careWho = stats.care_who;
		self.courseType = 'Engagement';
		self.courseSummary = stats.course_summary;
		self.courseBreakdown = stats.course_breakdown;
		applySnapshot(self, {
			...self, id: client.id, name: client.name, logo: client.logo,
			departments, departmentId, schoolId: 0,
			census: client.census,
			loading: false
		});
	})

	function logout() {
		// Update the states.
		reset();
	}

	function reset() {
		// Reset the timeframes.
		const startMonth = moment().subtract(1, 'month').startOf('month');
		const strStartMonth = startMonth.format(UTC_DATE_FORMAT);
		const endMonth = moment();
		const strEndMonth = endMonth.format(UTC_DATE_FORMAT);

		self.startDate = strStartMonth;
		self.endDate = strEndMonth;
		self.sales = [];
		self.salesStartDate = strStartMonth;
		self.salesEndDate = strEndMonth;
		self.courses = [];
		self.coursesStartDate = strStartMonth;
		self.coursesEndDate = strEndMonth;
		self.couponsSummary = [];
		self.couponsStartDate = strStartMonth;
		self.couponsEndDate = strEndMonth;
	}

	const setClient = flow(function* setClient(clientId: string) {
		// setup
		const client = clients.findByName(clientId);
		if (!client) {
			return;
		}
		applySnapshot(self, {...self, loading: true}); // for protection

		// TEMPORARY
		const departments = client.departments.map(d => {return {id: d.id, name: d.name}});
		const departmentId = "All Departments";

		const startDate = moment().subtract(1, 'year').startOf('month');
		const strStartDate = startDate.format(UTC_DATE_FORMAT);
		const endDate = moment().endOf('month');
		const strEndDate = endDate.format(UTC_DATE_FORMAT);

		// summary stats and engagements
		self.census = client.census;
		if (system.rootUrl.includes('haven')) {
			yield initHaven(client, strStartDate, strEndDate, departments, departmentId);
		} else if (system.rootUrl.includes('aaadrivingcourse')) {
			yield initAAA(client, strStartDate, strEndDate, departments, departmentId);
		} else if (system.rootUrl.includes('onq')) {
			yield initOnQ(client, strStartDate, strEndDate, departments, departmentId);
		} else {
			yield initVEST(client, strStartDate, strEndDate, departments, departmentId);
		}
	})

	function setDepartment(departmentId: string) {
		applySnapshot(self, {...self, departmentId});
	}

	function strCamelize(srcString: string) {
		return srcString.replace('_', ' ').replace(/\w+/g, function(w) {
			return w[0].toUpperCase() + w.slice(1).toLowerCase();
		});
	}

	function setDates(startDate: string, endDate: string) {
		self.startDate = startDate;
		self.endDate = endDate;
	}

	return {
		clearStats,
		fetchCouponAnalytics,
		fetchCoursesAnalytics,
		fetchSalesAnalytics,
		fetchStudentDetails,
		fetchSurveyAnalytics,
		generateReport,
		initialize,
		logout,
		strCamelize,
		setClient,
		setDates,
		setDepartment
	}
}).views(self => {
	return ({

		findResourceByCategory(resources: Resource[], item: Resource) {
			let resource = resources.find((r: Resource) => r.activity_category === item.activity_category);
			if (resource) {
				resource.engagements += item.engagements;
			} else {
				const title = self.strCamelize(item.activity_category);
				resources.push({
					activity_id: item.activity_id,
					activity_type: item.activity_type,
					activity_category: item.activity_category,
					activity_name: item.activity_name,
					activity_title: title,
					engagements: item.engagements,
					ave_time: item.ave_time
				});
			}
		},

		findResourceByTitle(resources: Resource[], item: Resource) {
			let resource = resources.find((r: Resource) => r.activity_title === item.activity_title);
			if (resource) {
				resource.engagements += item.engagements;
			} else {
				resources.push({
					activity_id: item.activity_id,
					activity_type: item.activity_type,
					activity_category: item.activity_category,
					activity_name: item.activity_name,
					activity_title: item.activity_title,
					engagements: item.engagements,
					ave_time: item.ave_time
				});
			}
		},

		getAnnualTotals() {
			return self.annualStats;
		},

		getCareTotals(group: string, type: string) {
			// setup
			let reasons: KeyValuePair[] =
				[
					{id: "Addiction", name: 'Addictions', value: 0, color: "#3D8361"},
					{id: "Anxiety", name: 'Anxiety', value: 0, color: "#790252"},
					{id: "Crisis", name: 'Crisis', value: 0, color: "#D61C4E"},
					{id: "Depression", name: 'Depression', value: 0, color: "#526185FF"},
					{id: "Grief or Loss", name: 'Grief or Loss', value: 0, color: "#6B7EADFF"},
					{id: "Relationships", name: 'Relationships', value: 0, color: "#5BB318"},
					{id: "Work Stress", name: 'Work Stress', value: 0, color: "#FF7F3F"},
					{id: "Referral/Support", name: 'Referral/Support', value: 0, color: "#AB9922FF"},
				];
			let whys: KeyValuePair[] =
				[
					{id: "Employee", name: 'Employee', value: 0, color: "#4D77FF"},
					{id: "Loved Ones", name: 'Loved Ones', value: 0, color: "#56BBF1"}
				];

			if (group === 'company' && type === 'reason') {
				for (let i = 0; i < reasons.length; i++) {
					const activity_name = reasons[i].id;
					const reason = self.careReason.find(v => v.activity_name === activity_name);
					if (reason) {
						reasons[i].name = reasons[i].name + ' ' + reason.company_percent + '%';
						reasons[i].value = reason.company_total;
					} else {
						reasons[i].name = reasons[i].name + ' 0%';
					}
				}
				return reasons;
			} else if (group === 'company') {
				for (let i = 0; i < whys.length; i++) {
					const activity_name = whys[i].id;
					const why = self.careWho.find(v => v.activity_name === activity_name);
					if (why) {
						whys[i].name = whys[i].name + ' ' + why.company_percent + '%';
						whys[i].value = why.company_total;
					} else {
						whys[i].name = whys[i].name + ' 0%';
					}
				}
				return whys;
			} else if (type === 'reason') {
				for (let i = 0; i < reasons.length; i++) {
					const activity_name = reasons[i].id;
					const reason = self.careReason.find(v => v.activity_name === activity_name);
					if (reason) {
						reasons[i].name = reasons[i].name + ' ' + reason.all_percent + '%';
						reasons[i].value = reason.all_total;
					} else {
						reasons[i].name = reasons[i].name + ' 0%';
					}
				}
				return reasons;
			} else {
				for (let i = 0; i < whys.length; i++) {
					const activity_name = whys[i].id;
					const why = self.careWho.find(v => v.activity_name === activity_name);
					if (why) {
						whys[i].name = whys[i].name + ' ' + why.all_percent + '%';
						whys[i].value = why.all_total;
					} else {
						whys[i].name = whys[i].name + ' 0%';
					}
				}
				return whys;
			}
		},

		getCourseBreakdown(): ActivitySummary[] {
			return self.courseBreakdown;
		},

		getCourseSummary(): ActivitySummary[] {
			return self.courseSummary;
		},

		getCourseType(): string {
			return self.courseType;
		},

		getLocationStats(): LocationSummary[] {
			return self.locationStats;
		},

		getMonthlyTotals(activityType: string) {
			const totals = [];
			console.log(activityType)
			for (let i = 0; i < self.monthlyStats.length; i++) {
				if (self.monthlyStats[i].activity_type === activityType) {
					totals.push(self.monthlyStats[i]);
				}
			}
			return totals;
		},

		getPollBreakdown(companyId: string) {
			return (companyId && companyId !== 'All Departments') ?
				self.pollBreakdown.filter(p => p.company === companyId) :
				self.pollBreakdown;
		},

		getPollSummary() {
			return self.pollSummary;
		},

		setCouponsTimeframe(startDate: string, endDate: string) {
			self.couponsStartDate = startDate;
			self.couponsEndDate = endDate;
		},

		setCoursesTimeframe(startDate: string, endDate: string) {
			self.coursesStartDate = startDate;
			self.coursesEndDate = endDate;
		},

		setSalesTimeframe(startDate: string, endDate: string) {
			self.salesStartDate = startDate;
			self.salesEndDate = endDate;
		}

	});
})
export type Client = Instance<typeof ClientInfo>;

export const defaultClient = ClientInfo.create({
	id: 0,
	name: "Blunovus", // Haven, AAA
	departments: [],
	departmentId: "",
	schoolId: 0,
	pageNavigation: {
		// general
		coupons: '',
		courses: '',
		engagement: '',
		insights: '',
		location: '',
		survey: '',
		sales: '',
		settings: '',
		students: '',
		// student
		s_return: 'static',
		s_orders: 'selected',
		s_courses: 'selected',
		s_exams: 'selected',
		s_certificates: 'selected',
		s_validation: 'show',
		s_logins: 'show',
		// other
		support: ''
	},
	// stats
	census: 0,
	url: packageJson.globals.ROOT_URL,
	logo: packageJson.globals.ROOT_LOGO,
	// status
	initialized: false,
	loading: false
});

/**
 * ClientsInfo - This structure is used for mobx storage efficiency.
 */

export const ClientsInfo = types.model("ClientsInfo", {
	// data
	clients: types.array(CompanyInfo),
	// status
	initialized: types.boolean,
	loading: types.boolean
}).actions(self => {

	function addClient() {

	}

	const fetchClientInfo = flow(function* fetchClientInfo(clientId: number) {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_company');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', clientId.toString());

		// Make the request.
		const results: any = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	})

	const fetchClients = flow(function* fetchClients() {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_companies');
		options.append('moodlewsrestformat', 'json');
		options.append('rootid', system.rootId);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	})

	const initialize = flow(function* initialize() {
		// Get the list of clients.
		if (self.initialized) {
			return;
		}
		const clients = yield fetchClients();

		// Update the states.
		applySnapshot(self, {...self, clients, initialized: true});
	})

	const updateClientInfo = flow(function* updateClientInfo(clientId: number, fields: NameValuePair[]) {
		// Make sure we have something to save.
		if (!fields.length) {
			return false;
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_set_company_details');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', clientId.toString());
		options.append('fields', JSON.stringify(fields));

		// Make the request.
		const results: any = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		return !(!results || results.status !== 200);
	})

	return {
		addClient,
		fetchClientInfo,
		initialize,
		updateClientInfo
	}
}).views(self => ({

	findById(clientId: number) {
		return self.clients.find(client => clientId === client.id);
	},

	findByName(clientId: string) {
		const lowerId = clientId.toLowerCase();
		return self.clients.find(client => {
			if (clientId == client.id.toString() || clientId == client.code || lowerId === client.code ||
				lowerId === client.shortname || clientId === client.shortname ||
				clientId === client.name) {
				 return true;
			}
			return false;
		});
	},

	mapChildOptions(parentId: number) {
		let children: any[] = [];
		self.clients.forEach(item => {
			if (item.parentid === parentId) {
				children.push(<option value={item.code}>{item.name}</option>);
			}
		});
		return children;
	},

	mapChildItems(items: any[], parentid: number, level: number, prefix: string) {
		// Create entries for each client.
		let count = 1;
		for (let i = 0; i < self.clients.length; i++) {
			const client = self.clients[i];
			if (client.parentid === parentid && client.level === level) {
				const key = 'client-'+client.code;
				const indent = prefix ? prefix + count++ + '.' : count++ + '.';
				items.push(<Dropdown.Item key={key} eventKey={client.code} className={'dropdown-level-'+level}>
					{indent + ' ' + client.name}
				</Dropdown.Item>);
				if (level < 2) {
					this.mapChildItems(items, client.id, level+1, indent);
				}
			}
		}
		return items;
	},

	mapParentItems(clientId: string | null) {
		// Create entries for each client.
		let items: any = [];
		if (clientId) {
			for (let i = 0; i < self.clients.length; i++) {
				const client = self.clients[i];
				if (client.name === clientId || client.shortname === clientId) {
					const key = 'client-'+client.code;
					items.push(<Dropdown.Item key={key} eventKey={client.code} className={'dropdown-level-0'}>
						{client.name}
					</Dropdown.Item>);
					this.mapChildItems(items, client.id, 1, '');
				}
			}
		} else if (!clientId) {
			const client = this.findByName(system.rootId);
			if (client) {
				const key = 'client-'+client.code;
				items.push(<Dropdown.Item key={key} eventKey={client.code} className={'dropdown-level-0'}>
					{client.name}
				</Dropdown.Item>);
				this.mapChildItems(items, client.id, 1, '');
			}
		} else {
			for (let i = 0; i < self.clients.length; i++) {
				const client = self.clients[i];
				if (!client.parentid || client.name === system.rootId || client.shortname === system.rootId) {
					const key = 'client-'+client.code;
					items.push(<Dropdown.Item key={key} eventKey={client.code} className={'dropdown-level-0'}>
						{client.name}
					</Dropdown.Item>);
					this.mapChildItems(items, client.id, 1, '');
				}
			}
		}
		return items;
	},

	mapParentOptions() {
		return self.clients.map(item => {
			return <option value={item.code}>{item.name}</option>
		});
	},

	mapSchoolItems(clientId: number | null) {
		// Create entries for each school (recursively).
		let items: any = [];
		for (let i = 0; i < self.clients.length; i++) {
			const client = self.clients[i];
			if (client.parentid === clientId && (client.code.includes('SCHOOL') || client.code.includes('school'))) {
				// item
				const key = 'client-'+client.code;
				items.push(<Dropdown.Item key={key} eventKey={client.code} className={'dropdown-school'}>
					{client.name}
				</Dropdown.Item>);
				// recursion
				const sItems = this.mapSchoolItems(client.id);
				if (sItems.length) {
					items = items.concat(sItems);
				}
			}
		}
		return items;
	},

}))

export const clients = ClientsInfo.create({
	initialized: false,
	loading: false,
	clients: []
})

/**
 * UserInfo - This structure is used for mobx storage efficiency.
 */

export const UserInfo = types.model("User", {
	id: types.maybeNull(types.string),
	mdl_id: types.number,
	name: types.maybeNull(types.string),
	auth: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	clientId: types.maybeNull(types.string),
	role: types.enumeration([ROLE_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_COMPANY_MANAGER, ROLE_DEPARTMENT_MANAGER, ROLE_USER]),
	view: types.enumeration([ROLE_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_COMPANY_MANAGER, ROLE_DEPARTMENT_MANAGER, ROLE_USER]),
	errorLevel: types.maybeNull(types.string),
	errorMessage: types.maybeNull(types.string),
}).volatile(_ => ({
	studentId: 0,
	courseId: 0,
	orderId: 0
})).actions(self => {

	/**
	 * Authenticate the user based on their email/password (or corporate login).
	 */
	const authenticate = flow(function* authenticate(email: string, password: string) {
		// admin user patterns
		const manager = ['@lecticon.com', '@blunovus.com', '@vesteap.com'];
		const admin = ['richard@lecticon.com', 'richard@blunovus.com', 'joel@vesteap.com', 'kyle.moon@lecticon.com', 'ted@lecticon.com', 'ted@blunovus.com'];

		// Authenticate the user
		const valid = yield mdlAuthenticate(email, password, manager, admin);
		if (!valid) {
			const errorMessage = "Invalid email or password";
			applySnapshot(self, {id: null, auth: null, mdl_id: 0, email: null, clientId: null,
				role: ROLE_USER, view: ROLE_USER,
				errorLevel: "danger", errorMessage});
		}

		// Return the results.
		return !!self.id;
	})

	const generateCertificate = async (userid: number, courseid: number) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_generate_certificate');
		options.append('moodlewsrestformat', 'json');
		options.append('userid', userid.toString());
		options.append('courseid', courseid.toString());
		options.append('responseType', 'blob');

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	}

	function logout() {
		system.clearStorage(LOGIN_SETTINGS);
		applySnapshot(self, defaultUser);
	}

	/**
	 * Authenticate through the MDL system.
	 */
	const mdlAuthenticate = flow(function* mdlAuthenticate(email: string, password: string, manager: string[], admin: string[]) {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_authenticate');
		options.append('moodlewsrestformat', 'json');
		options.append('source', 'reporting');
		options.append('companyid', system.rootId);
		options.append('departmentid', "");
		options.append('deviceid', "");
		options.append('username', email);
		options.append('password', password);
		options.append('metadata', "");

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);
		if (results && results.status === 200 && results.data.id) {
			// Determine the user's role.
			let user = results.data;
			let clientId = user.companyId ? user.companyId : system.rootId;
			if (!!admin.find(a => email.includes(a))) {
				user.role = user.view = ROLE_ADMIN;
				clientId = system.rootId;
			} else if (!!manager.find(a => email.includes(a))) {
				user.role = user.view = ROLE_ACCOUNT_MANAGER;
				clientId = system.rootId;
			} else if (user.role == ROLE_USER) {
				return false; // not allowed in the reporting system
			}

			// Apply the snapshot.
			applySnapshot(self, {...self,
				id: user.username, mdl_id: user.id, auth: user.token,
				name: user.firstname + ' ' + user.lastname,
				clientId,
				email: user.email, role: user.role,
				errorLevel: null, errorMessage: null});

			return true;
		} else if (!!admin.find(a => email.includes(a))) {
			applySnapshot(self, {...self,
				id: email, clientId: system.rootId,
				email: email, role: ROLE_ADMIN, view: ROLE_ADMIN,
				errorLevel: null, errorMessage: null});
			return true;
		} else if (!!manager.find(a => email.includes(a))) {
			applySnapshot(self, {...self,
				id: email, clientId: system.rootId, email: email,
				role: ROLE_ACCOUNT_MANAGER, view: ROLE_ACCOUNT_MANAGER,
				errorLevel: null, errorMessage: null});
			return true;
		}

		return false;
	})

	function setStudentInfo(studentId: number, orderId: number, courseId: number) {
		self.studentId = studentId;
		self.orderId = orderId;
		self.courseId = courseId;
	}

	function setView(view: string) {
		if (view !== self.view) {
			applySnapshot(self, {...self, view});
		}
		return view;
	}

	return {
		authenticate,
		generateCertificate,
		logout,
		setStudentInfo,
		setView
	}

}).views(self => ({

	isAccountManager() {
		return (self.role === ROLE_ADMIN || self.role === ROLE_ACCOUNT_MANAGER);
	},

	isAdmin() {
		return (self.role === ROLE_ADMIN);
	},

	isCompanyManager() {
		return (self.role === ROLE_ADMIN || self.role === ROLE_COMPANY_MANAGER || self.role === ROLE_ACCOUNT_MANAGER);
	},

	isDepartmentManager() {
		return (self.role === ROLE_ADMIN || self.role === ROLE_DEPARTMENT_MANAGER || self.role === ROLE_ACCOUNT_MANAGER);
	},

	isManager() {
		return (self.role === ROLE_ADMIN || self.role === ROLE_COMPANY_MANAGER || self.role === ROLE_DEPARTMENT_MANAGER || self.role === ROLE_ACCOUNT_MANAGER);
	},

}));

export type User = Instance<typeof UserInfo>;
export const defaultUser = UserInfo.create({
	id: null,
	mdl_id: 0,
	auth: null,
	name: null,
	email: null,
	clientId: null,
	role: ROLE_USER,
	errorLevel: null,
	errorMessage: null,
	view: ROLE_USER,
});
